<template>
  <v-app>
      <Sabana/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Sabana from '@/components/Sabana.vue'

export default {
  name: 'SabanaView',
  components: {
    Sabana
  }
}
</script>

